<template>
  <v-app>
    <v-app-bar app color="white" inverted-scroll height="100">
      <v-avatar class="mr-3" color="grey lighten-5" size="70">
        <v-img contain max-height="90%" src="img/logo-light.png"></v-img>
      </v-avatar>
      <v-toolbar-title class="font-weight-black headline black--text">
        TLGMSG
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="!$vuetify.breakpoint.mdAndDown">
        <v-btn
          text
          color="teal darken-3"
          @click="$vuetify.goTo('#about-us')"
          x-large
          >Equipe</v-btn
        >
        <v-btn
          text
          color="teal darken-3"
          @click="$vuetify.goTo('#features')"
          x-large
          >Funcionalidades</v-btn
        >
        <v-btn
          text
          color="teal darken-3"
          @click="$vuetify.goTo('#tutorials')"
          x-large
          >Tutoriais</v-btn
        >
        <v-btn
          text
          color="teal darken-3"
          @click="$vuetify.goTo('#pricing')"
          x-large
          >Preços</v-btn
        >
        <v-btn
          text
          color="black"
          v-if="!$API.IsLoggedIn()"
          @click="$router.push('/auth')"
          x-large
          >Login<v-icon class="ml-2">fas fa-sign-in-alt</v-icon></v-btn
        >
        <v-btn text color="black" v-else @click="$router.push('/main')" x-large
          >Acessar<v-icon class="ml-2">fas fa-door-open</v-icon></v-btn
        >
      </div>

      <v-menu v-else offset-y light>
        <template v-slot:activator="{ on }">
          <v-btn icon color="black" v-on="on">
            <v-icon>fa fa-bars</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            text
            color="teal darken-3"
            @click="$vuetify.goTo('#about-us')"
            x-large
            style="width: 200px;"
            >Equipe</v-btn
          >
        </v-list>
        <v-list>
          <v-btn
            text
            color="teal darken-3"
            @click="$vuetify.goTo('#features')"
            x-large
            style="width: 200px;"
            >Funcionalidades</v-btn
          >
        </v-list>
        <v-list>
          <v-btn
            text
            color="teal darken-3"
            @click="$vuetify.goTo('#tutorials')"
            x-large
            style="width: 200px;"
            >Tutoriais</v-btn
          >
        </v-list>
        <v-list>
          <v-btn
            text
            color="teal darken-3"
            @click="$vuetify.goTo('#pricing')"
            x-large
            style="width: 200px;"
            >Preços</v-btn
          >
        </v-list>
        <v-list>
          <v-btn
            v-if="!$API.IsLoggedIn()"
            text
            color="black"
            @click="$router.push('/auth')"
            x-large
            style="width: 200px;"
            >Login<v-icon class="ml-2">fas fa-sign-in-alt</v-icon></v-btn
          >
          <v-btn
            v-else
            text
            color="black"
            @click="$router.push('/main')"
            x-large
            style="width: 200px;"
            >Acessar<v-icon class="ml-2">fas fa-door-open</v-icon></v-btn
          >
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content class="pa-0">
      <section id="hero">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="img/bg2.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center mb-4"
                    cols="12"
                    tag="h1"
                    style="background-color: #00000099; border-radius: 25px;"
                  >
                    <span
                      :class="[
                        $vuetify.breakpoint.smAndDown
                          ? 'display-1'
                          : 'display-2'
                      ]"
                      class="font-weight-light"
                    >
                      Notificações no telegram
                    </span>

                    <br />

                    <span
                      :class="[
                        $vuetify.breakpoint.smAndDown
                          ? 'display-3'
                          : 'display-4'
                      ]"
                      class="font-weight-black"
                    >
                      TLGMSG
                    </span>
                  </v-col>
                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about-us')"
                  >
                    <v-icon>fa fa-arrow-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="about-us" class="pt-10">
        <div class="py-10"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">QUEM SOMOS</h2>

          <v-responsive class="mx-auto mb-8" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto headline font-weight-light mb-8 text-justify"
            max-width="900"
          >
            Somos uma equipe que busca soluções <b>práticas</b> para
            <i>tarefas repetitivas</i> do dia a dia. <br />
            Este projeto veio para facilitar o envio de mensagens para o
            telegram. Nós usamos a API dos bots do telegram para enviar
            mensagens em grupos e receber notificações.
          </v-responsive>
          <v-row align="end" class="fill-height mb-12">
            <v-col>
              <v-row align="end" class="fill-height">
                <v-col align-self="start" class="pa-0" cols="12">
                  <v-avatar class="profile" color="grey" size="164" tile>
                    <v-img src="img/lincon.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title"
                        >Lincon Rezende</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Computer Programmer</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row align="end" class="fill-height">
                <v-col align-self="start" class="pa-0" cols="12">
                  <v-avatar class="profile" color="grey" size="164" tile>
                    <v-img src="img/marcelof.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title"
                        >Marcelo Filho</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Computer Programmer</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="features" class="grey lighten-3 pt-10">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-1 font-weight-bold mb-3 black--text">
            FUNCIONALIDADES
          </h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in features"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-5 black--text"
                flat
              >
                <v-theme-provider>
                  <div>
                    <v-avatar color="teal darken-4" size="88">
                      <v-icon large v-text="icon"></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>
                <v-card-title
                  class="justify-center font-weight-black text-uppercase black--text"
                  v-text="title"
                ></v-card-title>
                <v-card-text class="subtitle-1 black--text" v-text="text">
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <v-sheet id="pricing" color="#333" dark tag="section" tile style="pt-10;">
        <div class="py-10"></div>
        <v-container>
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center py-10"
          >
            Preços
          </h2>
          <template>
            <v-row>
              <v-col cols="12" class="text-center">
                <p class="display-1">
                  Nós usamos o Mercado Pago como gateway de pagamento.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="title mb-0">ENTREGA 100% GARANTIDA</p>
                Entre em contato pelo whatsapp para receber os dados de acesso:
                <br />
                <b>+55 (48) 9 9865 - 7867</b>
              </v-col>
              <v-col cols="12" md="6">
                <p class="title mb-0">GARANTIA DE ATÉ 7 DIAS</p>
                Você pode solicitar o reembolso da assinatura em até 7 dias após
                o pagamento, é válido para todas as recorrências.
              </v-col>
              <v-col cols="12" class="text-center">
                <img src="img/mercado-pago-logo-5.png" />
              </v-col>
            </v-row>
          </template>
          <template>
            <v-row class="text-center">
              <v-col
                class="mx-auto"
                v-for="(item, ix) in prices"
                :key="'prices_' + ix"
                cols="12"
                md="12"
                lg="4"
              >
                <v-hover>
                  <div
                    slot-scope="{ hover }"
                    :class="`elevation-${hover ? 12 : 2}`"
                    style="border: solid 2px black;"
                  >
                    <h1 :class="`py-4 ${item.color} display-1`">
                      {{ item.title }}
                    </h1>
                    <div class="px-10 py-2">
                      <ul
                        class="text-left"
                        :style="
                          !$vuetify.breakpoint.mdAndDown
                            ? 'min-height: 100px;'
                            : ''
                        "
                      >
                        <li
                          v-for="(vl, ix2) in item.items"
                          :key="'items' + ix2"
                          v-html="vl"
                          class="title"
                        ></li>
                      </ul>
                      <v-divider class="my-5"></v-divider>
                      <h1 class="display-1" v-html="item.price"></h1>
                      <v-btn
                        class="my-5"
                        :color="item.btn.color"
                        @click="openLink(item.btn.href)"
                        x-large
                        width="250px"
                      >
                        {{ item.btn.text }}
                        <v-icon class="ml-3">fa fa-shopping-cart</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-sheet>
    </v-content>

    <v-footer class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Vuetify, LLC
      </div>
    </v-footer>
    <v-fab-transition>
      <v-btn
        fab
        large
        dark
        bottom
        right
        fixed
        @click="openLink('https://wa.me/5548998657867')"
      >
        <img src="img/002-whatsapp-1.png" />
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'auth',
  components: {},
  data: () => ({
    tutorials: [
      {
        idVideo: 'Ajr71AqUG58',
        title: 'Visão geral da plataforma',
        text: 'Tutorial básico das funcionalidades da plataforma.'
      }
    ],
    features: [
      {
        icon: 'fas fa-map-signs',
        title: 'Instant message',
        text: 'Receba alertas através de mensagens no telegram.'
      },
      {
        icon: 'fas fa-calculator',
        title: 'Relatórios',
        text: 'Relatório de envio das mensagens'
      },
      {
        icon: 'fas fa-share-alt',
        title: 'Integração',
        text:
          'Você tem acesso e liberdade para fazer integrações com outros serviços'
      }
    ],
    prices: [
      {
        title: 'EARLY ACCESS',
        color: 'red darken-4',
        items: [
          '<b>Envios ilimitados</b><br/>',
          'Preço válido até fevereiro 2021'
        ],
        price:
          '<span class="title"><i>de <span style="text-decoration: line-through;">37</span> por</i></span> R$20/mês',
        btn: {
          color: 'teal',
          text: 'COMPRAR AGORA',
          href: 'http://mpago.la/1FdLqws'
        }
      }
    ]
  }),
  methods: {
    openLink(_url) {
      window.open(_url, '_blank')
    }
  }
}
</script>
